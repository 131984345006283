import React, { Fragment, useState, useEffect } from 'react';
import Logo from '../../src/assets/logo/LogoJMX.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faBuilding, faCar, faEnvelope, faHandHoldingHeart, faHouse, faLocationDot, faMobileScreenButton, faPhone } from '@fortawesome/free-solid-svg-icons';
import fundoSobre from '../../src/assets/imagens/fundo-sobre2.webp';
import familia from '../../src/assets/imagens/familia.jpg';
import imagemMarcos from '../../src/assets/imagens/Marcos.webp';
import imagemMatteus from '../../src/assets/imagens/Matteus.webp';
import QRCode from 'react-qr-code';
import '../App.scss';
import axios from 'axios';
import { faFacebookSquare, faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

function HomeScreen() {
  const [conhecaMatteus, setConhecaMatteus] = useState(false);
  const [conhecaMarcos, setConhecaMarcos] = useState(false);
  const [modalCotacao, setModalCotacao] = useState(false);
  const [filiadoSindicato, setFiliadoSindicato] = useState('n-filiado');
  const [tipoSeguro, setTipoSeguro] = useState('');
  const [tipoPessoa, setTipoPessoa] = useState('pf');
  const [marcasVeiculo, setMarcasVeiculo] = useState([]);
  const [modelosVeiculo, setModelosVeiculo] = useState([]);
  const [anosVeiculo, setAnosVeiculo] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function solicitarCotacao(e) {
    e.preventDefault();

    const formData = new FormData(e.target);
    let data = Object.fromEntries(formData);
  };

  function setarPessoa(e) {
    setTipoPessoa(e.target.value);
  };

  function enviarContato(evt) {
    evt.preventDefault();

    const formData = new FormData(evt.target)
    let form = Object.fromEntries(formData);

    try {
        window.open(`https://api.whatsapp.com/send?phone=5544997710746&text=Ol%C3%A1,%20me%20chamo%20${form.nome},%20com%20o%20n%C3%BAmero%20de%20contato%20${form.telefone}%20e%20e-mail%20${form.email}.%0A%0AEntro%20em%20contato%20para%20falar%20sobre%20${form.assunto}.%0A%0A${form.mensagem}`);
    } catch (error) {
        console.log(error);
    }
  }

  async function getMarcas(e) {
    setMarcasVeiculo([]);
    setModelosVeiculo([]);
    setAnosVeiculo([]);
    const veiculos = await axios.get(`https://parallelum.com.br/fipe/api/v1/${e}/marcas`)
    setMarcasVeiculo(veiculos.data);
  };

  async function getModelos(e) {
    setModelosVeiculo([]);
    setAnosVeiculo([]);
    const veiculos = await axios.get(`https://parallelum.com.br/fipe/api/v1/${document.querySelector("#tipo-veiculo").value}/marcas/${e}/modelos`)
    setModelosVeiculo(veiculos.data.modelos);
  };

  async function getAnos(e) {
    setAnosVeiculo([]);
    const veiculos = await axios.get(`https://parallelum.com.br/fipe/api/v1/${document.querySelector("#tipo-veiculo").value}/marcas/${document.querySelector("#marca-veiculo").value}/modelos/${e}/anos`)
    setAnosVeiculo(veiculos.data);
  };

  return (
    <Fragment>
      <header>
        <img src={Logo} alt='Logo JMX' onClick={() => window.scroll({top: 0, behavior: 'smooth'})}/>
        <nav>
            <ul>
              <li onClick={() => window.scroll({top: document.getElementById("section-servicos").offsetTop - 30, behavior: 'smooth'})}>Seguros</li>
              <li onClick={() => window.scroll({top: document.getElementById("sobre-nos").offsetTop - 100, behavior: 'smooth'})}>Quem Somos</li>
              <li onClick={() => window.scroll({top: document.getElementById("corretores").offsetTop - 50, behavior: 'smooth'})}>Corretores</li>
              <li onClick={() => window.scroll({top: document.getElementById("contato").offsetTop - 50, behavior: 'smooth'})}>Contato</li>
              <li onClick={() => window.open('https://api.whatsapp.com/send?phone=5544997710746&text=Ol%C3%A1%2C%20gostaria%20de%20uma%20cota%C3%A7%C3%A3o%20de%20seguro.', '_blank')}>Cotar Seguro</li>
            </ul>
        </nav>
      </header>
      <section id='section-inicio'>
        <div className='conteudo-inicio'>
          <h1>Porquê você merece<br/>viver sem preocupação!</h1>
          <p>Os seguros são muito mais do que meras proteções contra imprevistos. Eles são a garantia da tranquilidade e da segurança financeira em momentos de dificuldade e incerteza.</p>
          <button onClick={() => window.open('https://api.whatsapp.com/send?phone=5544997710746&text=Ol%C3%A1%2C%20gostaria%20de%20uma%20cota%C3%A7%C3%A3o%20de%20seguro.', '_blank')}>Quero segurança</button>
        </div>
        <img src={familia} alt='Imagem de Familia Feliz'/>
      </section>
      <section id='section-servicos'>
        <h2>Temos as melhores soluções para <span>você</span> e sua <span>empresa</span></h2>
        <div className='conteudos-servicos'>
          <div className='tipo-servico'>
            <FontAwesomeIcon icon={faHandHoldingHeart}/>
            <h3>Seguro de Vida</h3>
            <p>O seguro de vida oferece proteção financeira em casos de imprevistos, garantindo tranquilidade e amparo para você e sua família.</p>
          </div>
          <div className='tipo-servico'>
            <FontAwesomeIcon icon={faCar}/>
            <h3>Seguro Veicular</h3>
            <p>Ao adquirir um veículo, faça um seguro conosco para proteger seu patrimônio com diversas coberturas disponíveis.</p>
          </div>
          <div className='tipo-servico'>
            <FontAwesomeIcon icon={faBuilding}/>
            <h3>Seguro Empresarial</h3>
            <p>O seguro empresarial protege a empresa contra imprevistos e garante a continuidade dos negócios.</p>
          </div>
          <div className='tipo-servico'>
            <FontAwesomeIcon icon={faHouse}/>
            <h3>Seguro Patrimonial</h3>
            <p>O seguro oferece proteção imediata para eventos adversos, com assistência básica e outras coberturas opcionais.</p>
          </div>
          <div className='tipo-servico'>
            <FontAwesomeIcon icon={faBriefcase}/>
            <h3>Responsabilidade Civil</h3>
            <p>O seguro de responsabilidade civil geral cobre prejuízos causados por atividades específicas, com diversas modalidades disponíveis.</p>
          </div>
          <div className='tipo-servico'>
            <FontAwesomeIcon icon={faMobileScreenButton}/>
            <h3>Seguro de Portáteis</h3>
            <p>O seguro portáteis protege dispositivos pessoais e profissionais contra danos, perdas e furtos.</p>
          </div>
        </div>
      </section>
      <section id='sobre-nos'>
        <img src={fundoSobre} alt='Imagem de um homem mostrando resultados para casal de idosos'/>
        <h2>Somos a corretora <span>certa</span></h2>
        <p>A JMX é a melhor escolha para quem busca segurança e tranquilidade. Desde 2013, oferecemos serviços completos de corretagem de seguros, com atendimento excepcional e total transparência. Nossa missão é proporcionar aos nossos clientes as melhores opções em seguros personalizados, com coberturas completas e os melhores preços junto às nossas seguradoras parceiras.<br/><br/>Confie na JMX e proteja o que é importante para você com práticas inovadoras e soluções sob medida.</p>
      </section>
      <section id='corretores'>
        <h2>Nosso <span>time</span> de corretores</h2>
        <div className='conteudo-corretores'>
          <div className='box-corretor'>
            <img src={imagemMatteus} alt='Foto do corretor Matteus'/>
            <h3>Matteus Oliveira</h3>
            <p>Com 8 anos de experiência, o corretor é especializado em seguro de vida, oferecendo soluções personalizadas para atender às necessidades de cada cliente e garantir sua segurança e tranquilidade.</p>
            {conhecaMatteus || document.body.clientWidth < 768 ?
              <Fragment>
                <div className='conheca-matteus'>
                  <a target='_blank' rel='noreferrer' href='https://api.whatsapp.com/send?phone=5544997710746&text=Ol%C3%A1%2C%20gostaria%20de%20uma%20cota%C3%A7%C3%A3o%20de%20seguro.'>
                    <QRCode value='https://api.whatsapp.com/send?phone=5544997710746&text=Ol%C3%A1%2C%20gostaria%20de%20uma%20cota%C3%A7%C3%A3o%20de%20seguro.'/>
                  </a>
                  <p>{document.body.clientWidth > 768 ? <Fragment>Aponte a câmera do seu celular no QR Code ou <a target='_blank' rel='noreferrer' href='https://api.whatsapp.com/send?phone=5544997710746&text=Ol%C3%A1%2C%20gostaria%20de%20uma%20cota%C3%A7%C3%A3o%20de%20seguro.'>clique aqui</a></Fragment> : <a target='_blank' rel='noreferrer' href='https://api.whatsapp.com/send?phone=5544997710746&text=_*Ol%C3%A1%2C%20gostaria%20de%20uma%20cota%C3%A7%C3%A3o%20de%20seguro.*_'>Clique aqui</a>} para falar com o Matteus pelo WhatsApp</p>
                  <hr/>
                  <div className='contatos-corretor'>
                    <div>
                      <FontAwesomeIcon icon={faWhatsapp}/>
                      <p>(44) 9 9771-0746</p>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faEnvelope}/>
                      <p>matteus@jmxcorretora.com.br</p>
                    </div>
                  </div>
                </div>
                <button className='btn-voltar' onClick={() => setConhecaMatteus(false)}>Voltar</button>
              </Fragment>
            : 
              <button onClick={() => setConhecaMatteus(true)}>Conheça</button>
            }
          </div>
          <div className='box-corretor'>
            <img src={imagemMarcos} alt='Foto do corretor Marcos'/>
            <h3>Marcos Fabri</h3>
            <p>Com mais de 25 anos de experiência, é expertise em diversos ramos de seguros. Com ética, transparência e comprometimento, ele atua com excelência na sua área, garantindo a segurança de seus clientes.</p>
            {conhecaMarcos || document.body.clientWidth < 768 ?
              <Fragment>
                <button className='btn-voltar' onClick={() => setConhecaMarcos(false)}>Voltar</button>
                <div className='conheca-marcos'>
                  <a target='_blank' rel='noreferrer' href='https://api.whatsapp.com/send?phone=5544997020502&text=Ol%C3%A1%2C%20gostaria%20de%20uma%20cota%C3%A7%C3%A3o%20de%20seguro.'>
                    <QRCode value='https://api.whatsapp.com/send?phone=5544997020502&text=Ol%C3%A1%2C%20gostaria%20de%20uma%20cota%C3%A7%C3%A3o%20de%20seguro.'/>
                  </a>
                  <p>Aponte a câmera do seu celular no QR Code ou <a target='_blank' rel='noreferrer' href='https://api.whatsapp.com/send?phone=5544997020502&text=Ol%C3%A1%2C%20gostaria%20de%20uma%20cota%C3%A7%C3%A3o%20de%20seguro.'>clique aqui</a> para falar com o Marcos pelo WhatsApp</p>
                  <hr/>
                  <div className='contatos-corretor'>
                    <div>
                      <FontAwesomeIcon icon={faWhatsapp}/>
                      <p>(44) 9 9702-0502</p>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faEnvelope}/>
                      <p>marcos@jmxcorretora.com.br</p>
                    </div>
                  </div>
                </div>
              </Fragment>
            : 
              <button onClick={() => setConhecaMarcos(true)}>Conheça</button>
            }
          </div>
        </div>
      </section>
      <section id='contato'>
        <form onSubmit={enviarContato}>
          <div className='campo-form campo-60'>
            <label htmlFor='nome-contato'>Seu nome</label>
            <input type='text' id='nome-contato' name='nome'/>
          </div>
          <div className='campo-form campo-40'>
            <label htmlFor='telefone-contato'>Telefone</label>
            <input type='text' id='telefone-contato' name='telefone'/>
          </div>
          <div className='campo-form campo-65'>
            <label htmlFor='email-contato'>Email</label>
            <input type='email' id='email-contato' name='email'/>
          </div>
          <div className='campo-form campo-35'>
            <label htmlFor='assunto-contato'>Assunto</label>
            <select id='assunto-contato' name='assunto' defaultValue="default">
              <option value="default" disabled>Selecione</option>
              <option value="Cotações">Cotações</option>
              <option value="Administrativo">Administrativo</option>
              <option value="Comercial">Comercial</option>
              <option value="Suporte">Suporte</option>
              <option value="Sinistro">Sinistro</option>
            </select>
          </div>
          <div className='campo-form campo-100'>
            <label htmlFor='mensagem-contato'>Mensagem</label>
            <textarea id="mensagem-contato" name='mensagem' autoComplete="off" maxLength="650" spellCheck="true" rows="12"/>
          </div>
          <button>Enviar</button>
        </form>
        <div className='conteudo-contato'>
          <h2>Manda uma <span>mensagem</span> pra gente!</h2>
          <p>Deixe seus dados no formulário {document.body.clientWidth > 768 ? 'ao lado' : 'abaixo'} ou entre em contato com a corretora pelos contatos abaixo.</p>
          <div className='contato-corretora'>
            <FontAwesomeIcon icon={faEnvelope}/>
            <p>atendimento@jmxcorretora.com.br</p>
          </div>
          <div className='contato-corretora'>
            <FontAwesomeIcon icon={faPhone}/>
            <p>
              (44) 3030-2927<br/>
              (44) 9 9771-0746
            </p>
          </div>
          <div className='contato-corretora'>
            <FontAwesomeIcon icon={faLocationDot}/>
            <p>
              Av. Carneiro Leão, 135, Sala 1204<br/>
              Zona 01, Maringá - Paraná
            </p>
          </div>
          <iframe title='Mapa com a localização da JMX' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3661.1197036897483!2d-51.94684458542573!3d-23.420042662272863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ecd0b43f4bc4b5%3A0x326f23a4177d46aa!2sJMX%20Corretora%20de%20Seguros!5e0!3m2!1spt-BR!2sbr!4v1651782322852!5m2!1spt-BR!2sbr" width="100%" height="100%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
      </section>
      <footer>
        <div>
            <a target='_blank' rel='noreferrer' href='https://instagram.com/jmxcorretora'>
              <FontAwesomeIcon icon={faInstagram}/>
            </a>
            <a target='_blank' rel='noreferrer' href='https://www.facebook.com/jmxcorretora'>
              <FontAwesomeIcon icon={faFacebookSquare}/>
            </a>
            <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/jmxcorretora'>
              <FontAwesomeIcon icon={faLinkedin}/>
            </a>
            <Link to={'/assistencias'}>
              <p>Assistências 24h</p>
            </Link>
            <a target='_blank' rel='noreferrer' href='https://www.unisind.com.br/sismov/index.php'>
              <p>Acesso ao Sistema</p>
            </a>
        </div>
        <p>JMX Corretora de Seguros &reg; {new Date().getFullYear()}. Todos os direitos reservados.</p>
      </footer>
      { modalCotacao ? 
        <div className='modal' onClick={() => {setModalCotacao(false); setTipoSeguro('default'); setFiliadoSindicato('n-filiado'); setMarcasVeiculo([]); setModelosVeiculo([]); setAnosVeiculo([]);}}>
          <form onSubmit={solicitarCotacao} onClick={(event) => {event.stopPropagation();}}>
            <h2><span>Proteja-se</span> agora!</h2>
            <p>Dê o primeiro passo para uma vida sem preocupação.</p>
            <div className='campos-cotacao'>
              <div className='campo-form campo-50'>
                <label htmlFor='nome-cotacao'>Seu nome</label>
                <input type='text' id='nome-cotacao' name='nome'/>
              </div>
              <div className='campo-form campo-50'>
                <label htmlFor='telefone-cotacao'>Telefone para contato</label>
                <input type='text' id='telefone-cotacao' name='telefone'/>
              </div>
              <div className='campo-form campo-60'>
                <label htmlFor='email-cotacao'>E-mail</label>
                <input type='email' id='email-cotacao' name='email'/>
              </div>
              <div className='campo-form campo-40'>
                <label htmlFor='tipo-cotacao'>Seguro</label>
                <select defaultValue="default" id='tipo-cotacao' name='tipo' onChange={(e) => setTipoSeguro(e.target.value)}>
                  <option value="default" disabled>Selecione</option>
                  <option value="veicular">Seguro de Veiculos</option>
                  <option value="vida">Seguro de Vida</option>
                  <option value="empresarial">Seguro Empresarial</option>
                  <option value="patrimonial">Seguro Patrimonial</option>
                  <option value="rc">Responsabilidade Civil</option>
                  <option value="portateis">Seguro de Portáteis</option>
                </select>
              </div>
              <div className='campo-form campo-50'>
                <label htmlFor='filiado-cotacao'>Filiado em algum sindicato?</label>
                <select defaultValue="default" id='filiado-cotacao' name='filiado' onChange={(e) => setFiliadoSindicato(e.target.value)}>
                  <option value="default" disabled>Selecione</option>
                  <option value="filiado">Sim</option>
                  <option value="n-filiado">Não</option>
                </select>
              </div>
              <div className='campo-form campo-50'>
                <label htmlFor='sindicato-cotacao'>Qual sindicato?</label>
                <select defaultValue="default" id='sindicato-cotacao' name='sindicato' disabled={filiadoSindicato === "filiado" ? false : true}>
                  <option value="default" disabled>Selecione</option>
                  <option>SIMAC Castro</option>
                  <option>SIMENCAL Campo Largo</option>
                  <option>SINDICOMP Imbituva</option>
                  <option>SINDIMÁRMORE</option>
                  <option>SINDIMONT</option>
                  <option>SINDIPISO Campo Largo</option>
                  <option>SINOFEL</option>
                  <option>SINTRACIMENTO</option>
                  <option>SINTRACON Curitiba</option>
                  <option>SINTRACON Francisco Beltrão</option>
                  <option>SINTRACON Ponta Grossa</option>
                  <option>SINTRAMADEIRA</option>
                  <option>SINTRAMADMÓVEIS</option>
                  <option>SINTRICOMU</option>
                  <option>SINTRIMMOC</option>
                  <option>SINTRIVEL</option>
                  <option>SITIM Jaguariaíva</option>
                  <option>SITRACOCIFOZ</option>
                  <option>SOE Maringá</option>
                  <option>SOMPAR</option>
                  <option>SOMSJOP</option>
                  <option>STICM Cianorte</option>
                  <option>STICM Guarapuava</option>
                  <option>STICM Irati</option>
                  <option>STICM Jataizinho</option>
                  <option>STICM Londrina</option>
                  <option>STICM Mal. Cândido Rondon</option>
                  <option>STICM Maringá</option>
                  <option>STICM Medianeira</option>
                  <option>STICM Paranaguá</option>
                  <option>STICM Paranavaí</option>
                  <option>STICM Pato Branco</option>
                  <option>STICM Telêmaco Borba</option>
                  <option>STICM Toledo</option>
                  <option>STICM Ubiratã</option>
                  <option>STICMA</option>
                  <option>STICMUVA</option>
                  <option>STIMMQI</option>
                </select>
              </div>
              { tipoSeguro !== "empresarial" && tipoSeguro !== "patrimonial" && tipoSeguro !== "rc" && tipoSeguro !== "portateis" ?
              <Fragment>
                <fieldset>
                  <input type='radio' name='pessoa-cotacao' id='pf-cotacao' value='pf' onChange={setarPessoa} checked={tipoPessoa === 'pf'}/>
                  <label htmlFor='pf-cotacao'>Pessoa Fisica</label>
                </fieldset>
                <fieldset>
                  <input type='radio' name='pessoa-cotacao' id='pj-cotacao' value='pj' onChange={setarPessoa} checked={tipoPessoa === 'pj'}/>
                  <label htmlFor='pj-cotacao'>Pessoa Juridica</label>
                </fieldset> 
              </Fragment>
              : null}
              {(() => {
                switch (true) {
                    case (tipoSeguro === "veicular"):
                      if (tipoPessoa === "pf") {
                        return(
                          <div className='campos-complementar-cotacao'>
                            <div className='campo-form campo-30'>
                              <label htmlFor='tipo-veiculo'>Tipo de Veículo</label>
                              <select defaultValue="default" id='tipo-veiculo' name='tipo-veiculo' onChange={(e) => {getMarcas(e.target.value)}}>
                                <option value="default" disabled>Selecione</option>
                                <option value="carros">Carros</option>
                                <option value="motos">Motos</option>
                                <option value="caminhoes">Caminhões e Utilitários</option>
                              </select>
                            </div>
                            <div className='campo-form campo-30'>
                              <label htmlFor='marca-veiculo'>Marca</label>
                              <select defaultValue="default" id='marca-veiculo' name='marca-veiculo' onChange={(e) => {getModelos(e.target.value)}}>
                                <option value="default">Selecione</option>
                                { marcasVeiculo.map((item, index) => (
                                  <option key={index} value={item.codigo}>{item.nome}</option>
                                ))}
                              </select>
                            </div>
                            <div className='campo-form campo-40'>
                              <label htmlFor='modelo-veiculo'>Modelo</label>
                              <select defaultValue="default" id='modelo-veiculo' name='modelo-veiculo' onChange={(e) => {getAnos(e.target.value)}}>
                                <option value="default">Selecione</option>
                                { modelosVeiculo.map((item, index) => (
                                  <option key={index} value={item.codigo}>{item.nome}</option>
                                ))}
                              </select>
                            </div>
                            <div className='campo-form campo-20'>
                              <label htmlFor='ano-veiculo'>Ano</label>
                              <select defaultValue="default" id='ano-veiculo' name='ano-veiculo'>
                                <option value="default">Selecione</option>
                                { anosVeiculo.map((item, index) => (
                                  <option key={index} value={item.nome === '32000' ? "Zero KM" : item.nome}>{item.nome === '32000' ? "Zero KM" : item.nome}</option>
                                ))}
                              </select>
                            </div>
                            <div className='campo-form campo-40'>
                              <label htmlFor='cobertura-veiculo'>Cobertura</label>
                              <select defaultValue="default" id='cobertura-veiculo' name='cobertura-veiculo'>
                                <option value="default" disabled>Selecione</option>
                                <option value="rcf">RCF</option>
                                <option value="roubo">Roubo</option>
                                <option value="rf">Roubo e Furto</option>
                                <option value="rfc">Roubo, Furto e Colisão (PT)</option>
                                <option value="compreensivo">Compreensivo (Total)</option>
                              </select>
                            </div>
                            <div className='campo-form campo-40'>
                              <label htmlFor='menor25-veiculo'>Menor de 25 anos?</label>
                              <select defaultValue="default" id='menor25-veiculo' name='menor25-veiculo'>
                                <option value="default" disabled>Selecione</option>
                                <option value="true">Sim</option>
                                <option value="false">Não</option>
                              </select>
                            </div>
                          </div>
                        );
                      } else {
                        return(
                          <div className='campos-complementar-cotacao'>
                            <div className='campo-form campo-50'>
                              <label htmlFor='cobertura-pj-veiculo'>Cobertura</label>
                              <select defaultValue="default" id='cobertura-pj-veiculo' name='cobertura-pj-veiculo'>
                                <option value="default" disabled>Selecione</option>
                                <option value="rcf">RCF</option>
                                <option value="roubo">Roubo</option>
                                <option value="rf">Roubo e Furto</option>
                                <option value="rfc">Roubo, Furto e Colisão (PT)</option>
                                <option value="compreensivo">Compreensivo (Total)</option>
                              </select>
                            </div>
                            <div className='campo-form campo-50'>
                              <label htmlFor='quantidade-veiculo'>Quantidade de veiculos</label>
                              <input min={2} type='number' id='quantidade-veiculo' name='quantidade-veiculo'/>
                            </div>
                          </div>
                        );
                      }
                    case (tipoSeguro === "vida"):
                      if (tipoPessoa === "pf") {
                        return(
                          <div className='campos-complementar-cotacao'>
                            <div className='campo-form campo-50'>
                              <label htmlFor='nascimento-vida'>Data de nascimento</label>
                              <input type='date' id='nascimento-vida' name='nascimento-vida'/>
                            </div>
                            <div className='campo-form campo-50'>
                              <label htmlFor='nascimento-vida'>Estado Civil</label>
                              <select defaultValue="default" id='nascimento-vida' name='nascimento-vida'>
                                <option value='default'>Selecione</option>
                                <option value='default'>Solteiro(a)</option>
                                <option value='default'>Casado(a)</option>
                                <option value='default'>Divorciado(a)</option>
                                <option value='default'>Viúvo(a)</option>
                                <option value='default'>Separado(a)</option>
                              </select>
                            </div>
                            <div className='campo-form campo-50'>
                              <label htmlFor='renda-vida'>Renda mensal</label>
                              <input type='number' id='renda-vida' name='renda-vida'/>
                            </div>
                            <div className='campo-form campo-50'>
                              <label htmlFor='indenização-vida'>Valor de indenização</label>
                              <input type='number' id='indenização-vida' name='indenização-vida' placeholder='Valor que irá receber em caso de sinistro'/>
                            </div>
                          </div>
                        );
                      } else {
                        return(
                          <div className='campos-complementar-cotacao'>
                            <div className='campo-form campo-50'>
                              <label htmlFor='cnpj-vida'>CNPJ</label>
                              <input min={14} type='number' id='cnpj-vida' name='cnpj-vida'/>
                            </div>
                            <div className='campo-form campo-50'>
                              <label htmlFor='funcionarios-vida'>Número de Funcionários</label>
                              <input min={3} type='number' id='funcionarios-vida' name='funcionarios-vida'/>
                            </div>
                          </div>
                        );
                      }
                    case (tipoSeguro === "empresarial"):
                      return(
                        <div className='campos-complementar-cotacao'>
                          <div className='campo-form campo-50'>
                            <label htmlFor='cnpj-empresarial'>CNPJ</label>
                            <input min={14} type='number' id='cnpj-empresarial' name='cnpj-empresarial'/>
                          </div>
                          <div className='campo-form campo-50'>
                            <label htmlFor='valor-empresarial'>Valor estimado do bem</label>
                            <input type='number' id='valor-empresarial' name='valor-empresarial'/>
                          </div>
                          <div className='campo-form campo-100'>
                            <label htmlFor='endereco-empresarial'>Endereço completo</label>
                            <input type='text' id='endereco-empresarial' name='endereco-empresarial'/>
                          </div>
                        </div>
                      );
                    case (tipoSeguro === "patrimonial"):
                      return(
                        <div className='campos-complementar-cotacao'>
                          <div className='campo-form campo-50'>
                            <label htmlFor='valor-patrimonial'>Valor estimado do bem</label>
                            <input type='number' id='valor-patrimonial' name='valor-patrimonial'/>
                          </div>
                          <div className='campo-form campo-50'>
                            <label htmlFor='renovacao-patrimonial'>Será renovação?</label>
                            <select defaultValue="default" id='renovacao-patrimonial' name='renovacao-patrimonial'>
                              <option value="default" disabled>Selecione</option>
                              <option value="true">Sim</option>
                              <option value="false">Não</option>
                            </select>
                          </div>
                          <div className='campo-form campo-100'>
                            <label htmlFor='endereco-patrimonial'>Endereço completo</label>
                            <input type='text' id='endereco-patrimonial' name='endereco-patrimonial'/>
                          </div>
                        </div>
                      );
                    case (tipoSeguro === "rc"):
                      return(
                        <div className='campos-complementar-cotacao'>
                          <div className='campo-form campo-50'>
                            <label htmlFor='cnpj-rc'>CNPJ</label>
                            <input min={14} type='number' id='cnpj-rc' name='cnpj-rc'/>
                          </div>
                          <div className='campo-form campo-50'>
                            <label htmlFor='funcionarios-rc'>Número de Funcionários</label>
                            <input type='number' id='funcionarios-rc' name='funcionarios-rc'/>
                          </div>
                          <div className='campo-form campo-100'>
                            <label htmlFor='endereco-rc'>Endereço completo</label>
                            <input type='text' id='endereco-rc' name='endereco-rc'/>
                          </div>
                        </div>
                      );
                    case (tipoSeguro === "portateis"):
                      return(
                        <div className='campos-complementar-cotacao'>
                          <div className='campo-form campo-50'>
                            <label htmlFor='equipamento-portateis'>Tipo de portátil</label>
                            <select defaultValue="default" id='equipamento-portateis' name='equipamento-portateis'>
                              <option value="default" disabled>Selecione</option>
                              <option value="c_filmadora">Câmera Filmadora</option>
                              <option value="c_fotografica">Câmera Fotográfica</option>
                              <option value="notebook">Notebook</option>
                              <option value="smartphone">Smartphone</option>
                              <option value="smartwatch">Smartwatch</option>
                              <option value="tablet">Tablet</option>
                            </select>
                          </div>
                          <div className='campo-form campo-50'>
                            <label htmlFor='valor-portateis'>Valor do equipamento</label>
                            <input type='number' id='valor-portateis' name='valor-portateis'/>
                          </div>
                        </div>
                      );
                    default:
                    break;
                }
              })()}
            </div>
            <button type='reset' onClick={() => {setFiliadoSindicato(''); setTipoSeguro('default'); setModalCotacao(false); setMarcasVeiculo([]); setModelosVeiculo([]); setAnosVeiculo([]);}}>Não quero segurança</button>
            <button type='submit'>Solicitar cotação agora</button>
          </form>
        </div>
      : null}
    </Fragment>
  );
}

export default HomeScreen;