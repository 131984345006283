import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../src/assets/logo/LogoJMX.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../App.scss';
import { faFacebookSquare, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function ErrorScreen() {
  const navigate = useNavigate();

  return (
    <div id='page-404'>
      <header>
        <img src={Logo} alt='Logo JMX' onClick={() => navigate('/')}/>
      </header>
      <main>
        <h1>404</h1>
        <h3>Página não encontrada :(</h3>
        <button onClick={() => navigate('/')}>Volte para o inicio</button>
      </main>
      <footer>
        <div>
            <a target='_blank' rel='noreferrer' href='https://instagram.com/jmxcorretora'>
              <FontAwesomeIcon icon={faInstagram}/>
            </a>
            <a target='_blank' rel='noreferrer' href='https://www.facebook.com/jmxcorretora'>
              <FontAwesomeIcon icon={faFacebookSquare}/>
            </a>
            <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/jmxcorretora'>
              <FontAwesomeIcon icon={faLinkedin}/>
            </a>
            <a href='/assistencias'>
              <p>Assistências 24h</p>
            </a>
            <a target='_blank' rel='noreferrer' href='https://www.unisind.com.br/sismov/index.php'>
              <p>Acesso ao Sistema</p>
            </a>
        </div>
        <p>JMX Corretora de Seguros &reg; {new Date().getFullYear()}. Todos os direitos reservados.</p>
      </footer>
    </div>
  );
}

export default ErrorScreen;