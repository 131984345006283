import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomeScreen from "./components/home";
import AssistenciasScreen from "./components/assistencias";
import ErrorScreen from "./components/error";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<HomeScreen/>}/>
      <Route exact path="/assistencias" element={<AssistenciasScreen/>}/>
      <Route exact path="*" element={<ErrorScreen/>}/>
    </Routes>
  </BrowserRouter>
)

export default App;