import React, { Fragment, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../src/assets/logo/LogoJMX.jpg';
import LogoAllianz from '../../src/assets/imagens/seguradoras/logo_Allianz.svg';
import LogoAzul from '../../src/assets/imagens/seguradoras/logo_Azul.svg';
import logoBerkley from '../../src/assets/imagens/seguradoras/logo_Berkley.webp';
import logoBradesco from '../../src/assets/imagens/seguradoras/logo_Bradesco.webp';
import logoCapemisa from '../../src/assets/imagens/seguradoras/logo_Capemisa.webp';
import logoCentauro from '../../src/assets/imagens/seguradoras/logo_Centauro.webp';
import logoChubb from '../../src/assets/imagens/seguradoras/logo_Chubb.webp';
import logoEssor from '../../src/assets/imagens/seguradoras/logo_Essor.webp';
import logoHDI from '../../src/assets/imagens/seguradoras/logo_HDI.webp';
import logoIcatu from '../../src/assets/imagens/seguradoras/logo_Icatu.webp';
import logoItau from '../../src/assets/imagens/seguradoras/logo_Itau.webp';
import logoJunto from '../../src/assets/imagens/seguradoras/logo_Junto.svg';
import logoLiberty from '../../src/assets/imagens/seguradoras/logo_Liberty.webp';
import logoMag from '../../src/assets/imagens/seguradoras/logo_Mag.svg';
import logoMapfre from '../../src/assets/imagens/seguradoras/logo_Mapfre.webp';
import logoMbm from '../../src/assets/imagens/seguradoras/logo_Mbm.webp';
import logoMetlife from '../../src/assets/imagens/seguradoras/logo_Metlife.webp';
import logoMitsui from '../../src/assets/imagens/seguradoras/logo_Mitsui.svg';
import logoPortoSeguro from '../../src/assets/imagens/seguradoras/logo_PortoSeguro.webp';
import logoPrevisul from '../../src/assets/imagens/seguradoras/logo_Previsul.webp';
import logoSancor from '../../src/assets/imagens/seguradoras/logo_Sancor.svg';
import logoSompo from '../../src/assets/imagens/seguradoras/logo_Sompo.webp';
import logoSuhai from '../../src/assets/imagens/seguradoras/logo_Suhai.svg';
import logoSulamerica from '../../src/assets/imagens/seguradoras/logo_Sulamerica.webp';
import logoTokio from '../../src/assets/imagens/seguradoras/logo_Tokio.webp';
import logoUnimed from '../../src/assets/imagens/seguradoras/logo_Unimed.webp';
import logoZurich from '../../src/assets/imagens/seguradoras/logo_Zurich.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../App.scss';
import { faFacebookSquare, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function AssistenciasScreen() {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <Fragment>
      <header>
        <img src={Logo} alt='Logo JMX' onClick={() => navigate('/')}/>
      </header>
      <div id="content-assistencias">
        <h1>Assistências 24h</h1>
        <div class="assistencia-seguradora">
          <div class="logo-seguradora">
            <img src={LogoAllianz} alt='Logo Allianz'/>
          </div>
          <div class="dados-seguradora">
            <h3>Allianz</h3>
            <div class="conteudo-assistencia">
              <p>Auto:</p>
              <p>0800 013 0700</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Mercosul:</p>
              <p>+55 11 4331 5126</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Vidros:</p>
              <p>0800 701 1170</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Residência:</p>
              <p>0800 017 7178</p>
            </div>
            <div class="conteudo-assistencia">
              <p>SAC:</p>
              <p>0800 011 5215</p>
            </div>
          </div>
        </div>
        <div class="assistencia-seguradora">
          <div class="logo-seguradora">
            <img src={LogoAzul} alt='Logo Azul'/>
          </div>
          <div class="dados-seguradora">
            <h3>Azul Seguros</h3>
            <div class="conteudo-assistencia">
              <p>Capitais e Grandes Centros:</p>
              <p>4004-3700</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Outras Regiões:</p>
              <p>0300 123 2985 / 0800 703 0203</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Mercosul:</p>
              <p>+55 (11) 3366-2986</p>
            </div>
          </div>
        </div>
        <div class="assistencia-seguradora">
          <div class="logo-seguradora">
            <img src={logoBerkley} alt='Logo Berkley'/>
          </div>
          <div class="dados-seguradora">
            <h3>Berkley Seguros</h3>
            <div class="conteudo-assistencia">
              <p>SAC:</p>
              <p>0800 777 3123</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Ouvidoria Berkley:</p>
              <p>0800 797 3444</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Sinistros:</p>
              <p>0800 770 0797</p>
            </div>
          </div>
        </div>
        <div class="assistencia-seguradora">
          <div class="logo-seguradora">
            <img src={logoBradesco} alt='Logo Bradesco'/>
          </div>
          <div class="dados-seguradora">
            <h3>Bradesco Seguros</h3>
            <div class="conteudo-assistencia">
              <p>Geral:</p>
              <p>4004 2757 ou 0800 701 2757</p>
            </div>
          </div>
        </div>
        <div class="assistencia-seguradora">
          <div class="logo-seguradora">
            <img src={logoCapemisa} alt='Logo Capemisa'/>
          </div>
          <div class="dados-seguradora">
            <h3>Capemisa Seguros</h3>
            <div class="conteudo-assistencia">
              <p>Assistência Apoio Emocional:</p>
              <p>0800 282 1522</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Assistência Automóvel:</p>
              <p>0800 770 0351</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Assistência Cesta Alimentação:</p>
              <p>0800 282 1522</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Assistência Dental de Urgência:</p>
              <p>0800 220 1208</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Assistência Domiciliar:</p>
              <p>0800 282 1522</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Assistência Empresarial:</p>
              <p>0800 770 0351</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Assistência Motocicleta:</p>
              <p>0800 770 0351</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Assistência Pet:</p>
              <p>0800 282 1522</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Kit Mamãe e Bebê:</p>
              <p>0800 282 1522</p>
            </div>
            <h5>Assistência Funeral</h5>
            <div class="conteudo-assistencia">
              <p>Brasil:</p>
              <p>0800 282 1522</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Exterior:</p>
              <p>+55 (24) 2483-9501 e +55 (21) 3090-1175</p>
            </div>
            <h5>Translado de Corpo</h5>
            <div class="conteudo-assistencia">
              <p>Brasil:</p>
              <p>0800 282 1522</p>
            </div>
            <div class="conteudo-assistencia">
              <p>Exterior:</p>
              <p>+55 (24) 2483-9500 e +55 (21) 3090-1175</p>
            </div>
          </div>
        </div>
        <div class="assistencia-seguradora">
          <div class="logo-seguradora">
            <img src={logoCentauro} alt='Logo Centauro'/>
          </div>
            <div class="dados-seguradora">
                <h3>Centauro Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>Assistência Funeral:</p>
                    <p>0800 770 0711</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Serviço de atendimento ao deficiente auditivo e de fala:</p>
                    <p>0800 604 4004</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Seguros de pessoas individual:</p>
                    <p>0800 771 5484</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Seguros de pessoas coletivo:</p>
                    <p>0800 770 0836</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Ouvidoria:</p>
                    <p>0800 643 7614</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
          <div class="logo-seguradora">
            <img src={logoChubb} alt='Logo Chubb'/>
          </div>
            <div class="dados-seguradora">
                <h3>Chubb Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>Afinidades:</p>
                    <p>0800 772 8550</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoEssor} alt='Logo Essor'/>
            </div>
            <div class="dados-seguradora">
                <h3>Essor Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>SAC:</p>
                    <p>0800 777 0438</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Seguro Auto Frota:</p>
                    <p>0800 201 9800</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoHDI} alt='Logo HDI'/>
            </div>
            <div class="dados-seguradora">
                <h3>HDI Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>Whatsapp:</p>
                    <p>+55 800 770 1608</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Regiões Metropolitanas:</p>
                    <p>3003-5390</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Demais Regiões:</p>
                    <p>0800 434 4340</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Atendimento Mercosul:</p>
                    <p>+55 (11) 4133 6636</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoIcatu} alt='Logo Icatu'/>
            </div>
            <div class="dados-seguradora">
                <h3>Icatu Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>Capitais e regiões metropolitanas:</p>
                    <p>4002 0040</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Demais localidades:</p>
                    <p>0800 285 3000</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>SAC:</p>
                    <p>0800 286 0110</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Ligações do exterior:</p>
                    <p>+55 (11) 4002-0114</p>
                </div>
                <h5>Serviço de Assistência Funeral - SAF:</h5>
                <div class="conteudo-assistencia">
                    <p>Capitais e regiões metropolitanas:</p>
                    <p>0800 026 1900</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Exterior 24h:</p>
                    <p>+55 (11) 4133-9299</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoItau} alt='Logo Itaú'/>
            </div>
            <div class="dados-seguradora">
                <h3>Itau Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>Capitais e Regiões Metropolitanas:</p>
                    <p>4004 4444</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Demais localidades:</p>
                    <p>0800 727 4444</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoJunto} alt='Logo Junto'/>
            </div>
            <div class="dados-seguradora">
                <h3>Junto Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>Matriz:</p>
                    <p>0800 704 0301</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>SAC:</p>
                    <p>0800 704 0301</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Deficiente auditivo ou de fala:</p>
                    <p>0800 742 6060</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoLiberty} alt='Logo Liberty'/>
            </div>
            <div class="dados-seguradora">
                <h3>Liberty Seguros</h3>
                <h5>Central de Atendimento</h5>
                <div class="conteudo-assistencia">
                    <p>Capitais e regiões metropolitanas:</p>
                    <p>4004 5423</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Demais localidades:</p>
                    <p>0800 709 5423</p>
                </div>
                <h5>Funcionamento:</h5>
                <div class="conteudo-assistencia">
                    <p>Informações e Abertura de sinistro:</p>
                    <p>Segunda a Sexta das 08h às 19h</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Demais atendimentos:</p>
                    <p>Segunda a Sexta das 08h às 20h</p>
                </div>
                <h5>Assistência 24h:</h5>
                <div class="conteudo-assistencia">
                    <p>Auto/Vida:</p>
                    <p>0800 701 4120</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Empresa/Residências:</p>
                    <p>0800 702 5100</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>SAC:</p>
                    <p>0800 726 1981</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Atendimento a deficientes auditivos:</p>
                    <p>0800 721 9104</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Ouvidoria 2ª à 6ª (horário comercial):</p>
                    <p>0800 740 3994</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Atendimento a deficientes auditivos:</p>
                    <p>0800 721 9104</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoMag} alt='Logo Mag'/>
            </div>
            <div class="dados-seguradora">
                <h3>MAG Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>SAC:</p>
                    <p>0800 725 7730</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Serviços de Assistência para clientes Vida Individual:</p>
                    <p>0800 026 0909</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Serviços de Assistência para clientes Vida em Grupo:</p>
                    <p>0800 770 4369</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Central para Deficiente Auditivo e de Fala:</p>
                    <p>0800 771 5472</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoMapfre} alt='Logo Mapfre'/>
            </div>
            <div class="dados-seguradora">
                <h3>Mapfre Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>WhatsApp:</p>
                    <p>+55 (11) 4004-0101</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoMbm} alt='Logo MBM'/>
            </div>
            <div class="dados-seguradora">
                <h3>MBM Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>Assistência Funeral, Residencial, Pet e Psicológica:</p>
                    <p>0800 603 2825</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Assistência Auto:</p>
                    <p>0800 940 0536</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoMetlife} alt='Logo Metlife'/>
            </div>
            <div class="dados-seguradora">
                <h3>MetLife Seguros</h3>
                <h5>Vida e Previdência</h5>
                <div class="conteudo-assistencia">
                    <p>Capitais e Grandes Centros:</p>
                    <p>0800-638-5433</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Demais Localidades:</p>
                    <p>0800-638-5433</p>
                </div>
                <h5>Planos Odontológicos</h5>
                <div class="conteudo-assistencia">
                    <p>Capitais e Grandes Centros:</p>
                    <p>3003-3422</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Demais Localidades:</p>
                    <p>0800-746-3422</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoMitsui} alt='Logo Mitsui'/>
            </div>
            <div class="dados-seguradora">
                <h3>Mitsui Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>Assistência 24h Veículo:</p>
                    <p>0800-707-7883</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Assistência 24h Vidros:</p>
                    <p>0800-707-7818</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Assistência Jurídica:</p>
                    <p>0300-772-6744</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>SAC:</p>
                    <p>0800-773-6744</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Ouvidoria:</p>
                    <p>0800-888-6744</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>WhatsApp:</p>
                    <p>+55 (11) 3177-5700</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoPortoSeguro} alt='Logo Porto Seguro'/>
            </div>
            <div class="dados-seguradora">
                <h3>Porto Seguro</h3>
                <div class="conteudo-assistencia">
                    <p>WhatsApp:</p>
                    <p>+55 (11) 3003 9303</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Central de Atendimento (Grande São Paulo):</p>
                    <p>3337 6786</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Central de Atendimento (Capitais e Regiões Metropolitanas):</p>
                    <p>4004 76786</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Central de Atendimento (Demais localidades):</p>
                    <p>0300 337 6786</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Mercosul:</p>
                    <p>+55 (11) 3366 3189</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoPrevisul} alt='Logo Previsul'/>
            </div>
            <div class="dados-seguradora">
                <h3>Previsul Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>WhatsApp:</p>
                    <p>+55 (11) 3003-6773</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Capitais e cidades metropolitanas:</p>
                    <p>3003-6773</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Demais localidades:</p>
                    <p>0800-709-8059</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoSancor} alt='Logo Sancor'/>
            </div>
            <div class="dados-seguradora">
                <h3>Sancor Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>Capitais e regiões metropolitanas:</p>
                    <p>4003 0393</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Demais localidades:</p>
                    <p>0800 200 0393</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoSompo} alt='Logo Sompo'/>
            </div>
            <div class="dados-seguradora">
                <h3>Sompo Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>Central de Atendimento:</p>
                    <p>0800 016 2727</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoSuhai} alt='Logo Suhai'/>
            </div>
            <div class="dados-seguradora">
                <h3>Suhai Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>Assistência 24 horas:</p>
                    <p>0800 327 8424</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Central de Localização/Comunicação Roubo ou Furto:</p>
                    <p>3003-0335</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Ouvidoria:</p>
                    <p>0800 772 1214</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
            <div class="logo-seguradora">
              <img src={logoSulamerica} alt='Logo Sulamerica'/>
            </div>
            <div class="dados-seguradora">
                <h3>SulAmérica Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>WhatsApp:</p>
                    <p>+55 (11) 3004-9723</p>
                </div>
                <h5>Solicitações de assistência funeral, assistência pessoal e demais serviços emergenciais:</h5>
                <div class="conteudo-assistencia">
                    <p>Capitais e Regiões Metropolitanas:</p>
                    <p>4090 1073</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Demais localidades:</p>
                    <p>0800 778 1073</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Exterior:</p>
                    <p>+55 (11) 4126-9317</p>
                </div>
                <h5>Aviso e acompanhamento de sinistros, informações sobre o seguro e demais solicitações da apólice contratada:</h5>
                <div class="conteudo-assistencia">
                    <p>Capitais e grandes cidades do interior:</p>
                    <p>4004-4935</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Demais localidades:</p>
                    <p>0800-726-4935</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Reclamações, cancelamento e informações gerais:</p>
                    <p>0800-722-0504</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
          <div class="logo-seguradora">
            <img src={logoTokio} alt='Logo Tokio Marine'/>
          </div>
            <div class="dados-seguradora">
                <h3>Tokio Marine Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>Automóvel:</p>
                    <p>0800 318 6546</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Auto Frota:</p>
                    <p>0800 328 6546</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Carro Reserva:</p>
                    <p>0800 208 6546</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Residencial:</p>
                    <p>0800 308 6546</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Vida:</p>
                    <p>0800 707 5050</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
          <div class="logo-seguradora">
            <img src={logoUnimed} alt='Logo Unimed'/>
          </div>
            <div class="dados-seguradora">
                <h3>Unimed Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>Central de atendimento:</p>
                    <p>0800 016 6633</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Deficientes Auditivos:</p>
                    <p>0800 770 3611</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>SAC:</p>
                    <p>0800 016 6633</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Assistência Residencial 24h:</p>
                    <p>0800 016 6633 - Opção 4</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Assistência Empresarial 24h:</p>
                    <p>0800 016 6633 - Opção 4</p>
                </div>
            </div>
        </div>
        <div class="assistencia-seguradora">
          <div class="logo-seguradora">
            <img src={logoZurich} alt='Logo Zurich'/>
          </div>
            <div class="dados-seguradora">
                <h3>Zurich Seguros</h3>
                <div class="conteudo-assistencia">
                    <p>WhatsApp:</p>
                    <p>+55 (11) 2890-2121</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Território Nacional:</p>
                    <p>0800 729 1400</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>Exterior:</p>
                    <p>+55 (11) 4133 6932</p>
                </div>
                <div class="conteudo-assistencia">
                    <p>SAC e Atendimento para deficientes auditivos:</p>
                    <p>0800 284 4848</p>
                </div>
            </div>
        </div>
      </div>
      <footer>
        <div>
            <a target='_blank' rel='noreferrer' href='https://instagram.com/jmxcorretora'>
              <FontAwesomeIcon icon={faInstagram}/>
            </a>
            <a target='_blank' rel='noreferrer' href='https://www.facebook.com/jmxcorretora'>
              <FontAwesomeIcon icon={faFacebookSquare}/>
            </a>
            <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/jmxcorretora'>
              <FontAwesomeIcon icon={faLinkedin}/>
            </a>
            <a href='/assistencias'>
              <p>Assistências 24h</p>
            </a>
            <a target='_blank' rel='noreferrer' href='https://www.unisind.com.br/sismov/index.php'>
              <p>Acesso ao Sistema</p>
            </a>
        </div>
        <p>JMX Corretora de Seguros &reg; {new Date().getFullYear()}. Todos os direitos reservados.</p>
      </footer>
    </Fragment>
  );
}

export default AssistenciasScreen;